import React from "react"
import { graphql, Link } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Layout from "../../components/layout"
import Hero from "../../components/hero"
import InnerWrapper from "../../components/innerWrapper"
import Team from "../../components/team"
import SEO from "../../SEO"
import styles from "../../components/scss/team.module.scss"
import GA from "../../components/ga"

class AboutPage extends React.Component {
  render() {

    const { data } = this.props
    return (
      <>
        <GA />
        <Layout>
          <SEO
            title="About"
            pathname="/about"
            description={data.contentfulSeo.aboutPageSeoDescription}
          />
          <Hero
            slogan={data.contentfulAboutPageHeroSlogan.slogan}
            cta={false}
          />
          <section className={styles.about} id="about">
            <InnerWrapper width={1024}>
              {/* <h2>Content Visit</h2> */}
              {documentToReactComponents(data.contentfulAbout.text.json)}
              <Team />

              <div className={styles.more}>
                <Link to="/services#services">
                  View Our Services <span>→</span>
                </Link>
              </div>
            </InnerWrapper>
          </section>
        </Layout>

      </>
    )
  }
}

export const query = graphql`
  query AboutQuery {
    contentfulSeo {
      aboutPageSeoDescription
    }
    contentfulAbout {
      text {
        json
      }
    }
    contentfulAboutPageHeroSlogan {
      slogan
    }
  }
`

export default AboutPage
