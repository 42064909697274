import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import styles from "./scss/team.module.scss"

const team = () => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulTeamMember {
          edges {
            node {
              name
              title
              bio {
                json
              }
              image {
                fixed(width: 300, height: 300, quality: 100) {
                  srcWebp
                  src
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <section className={styles.team}>
        <h2 id="team">Meet the team</h2>
        {data.allContentfulTeamMember.edges.map((edge, index) => {
          return (
            <div className={styles.bio} key={index}>
              <div className={styles.top}>
                <picture>
                  <source
                    srcSet={edge.node.image.fixed.srcWebp}
                    type="image/webp"
                  />
                  <source srcSet={edge.node.image.fixed.src} type="image/png" />
                  <img
                    className={styles.image}
                    src={edge.node.image.fixed.src}
                    alt={edge.node.image.description}
                  />
                </picture>
                <div className={styles.details}>
                  <h3 className={styles.name}>{edge.node.name}</h3>
                  <span className={styles.title}>{edge.node.title}</span>
                </div>
              </div>
              {documentToReactComponents(edge.node.bio.json)}
            </div>
          )
        })}
      </section>
    )}
  />
)

export default team
